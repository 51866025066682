import React, { useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Link from '../components/Link';
import SEO from '../components/SEO';
import Image from '../components/Image';
import Stats from '../components/Stats';
import Testimonial from '../components/Testimonial';
import ThreeCTABoxes from '../components/ThreeCTABoxes';
import parseHeading from '../utils/parseHeading';
import Block from '../assets/images/blocks/single-block-1.inline.svg';

import styled from 'styled-components';
import tw from 'twin.macro';
import media from '../utils/media';

import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const Graphic = styled.div`
  ${media.lessThan('md')`
    margin-left:-2rem;
    margin-right:-2rem;
    width:calc(100% + 4rem);
  `}
  ${media.lessThan('sm')`
    margin-left:-3.25rem;
    margin-right:-3.25rem;
    width:calc(100% + 6.5rem);
  `}
`;

const GraphicTop = styled(Graphic)`
  ${tw`mx-auto w-full`}
  max-width:995px;
  transform-origin: bottom center;
  ${media.greaterThan('md')`
    /* margin-bottom:-1.5%; */
  `}
`;

const GraphicBottom = styled(Graphic)`
  ${tw`mx-auto w-full`}
  max-width:836px;
  margin-top: -25%;
  transform-origin: top center;
  ${media.greaterThan('md')`
    margin-top:-20%;
  `}
  ${media.greaterThan('lg')`
    margin-top:-16%;
  `}
  ${media.greaterThan('xl')`
    margin-top:-13.5%;
  `}
`;

const JobPreview = styled.div`
  a {
    .line {
      margin: 0.5rem 0;
      height: 2px;
      ${tw`relative bg-black overflow-hidden`}

      &:before {
        content: '';
        ${tw`absolute inset-0 bg-purple`}
        transform:translateX(-100%);
        transition: transform 0.3s ease-in-out;
      }
    }

    &:hover .line {
      &:before {
        transform: none;
      }
    }
  }
`;

const TeamCard = styled.div`
  ${tw`rounded-sm h-full text-white flex items-end`}
  background:#9E9E9E;
  &:before {
    content: '';
    ${tw`absolute inset-0 rounded-sm`}
    background:#D9D8D8;
    transform: translate(8px, 8px);
    z-index: -1;
  }

  .darken-image {
    &:after {
      content: '';
      ${tw`absolute inset-0 rounded-sm`}
      background:var(--black);
      opacity: 0.13;
    }
  }
`;

export default (props) => {
  const { aboutPage, seo } = props.data.wpPage;
  const { hero, aboutIntro, mission, team, customers, testimonial, openRoles, ctaBoxes } = aboutPage;
  const jobPosts = props.data.allWpCareer.edges;
  const heroRef = useRef();
  const headingRef = useRef();
  const topRef = useRef();
  const bottomRef = useRef();

  useEffect(() => {
    const tl = gsap
      .timeline({
        ease: 'power3.in',
        scrollTrigger: {
          trigger: heroRef.current,
          scrub: true,
          start: 'top top+=60px',
          end: 'bottom top',
          markers: false,
          onUpdate: ({ progress }) => {},
        },
      })
      .to(headingRef.current, { opacity: 0 })
      .to([topRef.current, bottomRef.current], { scale: 1.2 }, '0')
      .to([topRef.current, bottomRef.current], { duration: 0.5, opacity: 0 }, '0')
      .to(topRef.current, { y: '-20%' }, '0')
      .to(bottomRef.current, { y: '20%' }, '0');

    return () => {
      tl.kill();
    };
  }, [heroRef.current]);

  // Get list of departments
  const depts = [...new Set(jobPosts.flatMap(({ node }) => node.termNames))];
  // Group jobs by department
  const groupedByDept = depts.map((d) => {
    return {
      name: d,
      items: jobPosts.filter(({ node }) => {
        return node && node.termNames && node.termNames.includes(d);
      }),
    };
  });

  return (
    <Layout>
      <SEO
        seo={seo}
        bodyAttributes={{
          class: 'page--about is-dark',
        }}
      />

      <section className="max-w-screen overflow-hidden about-hero bg-d-black text-white pad-t--xs pad-b text-center">
        <div ref={heroRef} className="container">
          {/* <div className="h-screen  flex flex-col justify-center items-center" style={{ maxHeight: '1000px' }}> */}
          <GraphicTop ref={topRef}>
            <div className="o-ratio o-ratio--about-hero-top">
              <div className="o-ratio__content">
                <Image data={hero.graphicTop} />
              </div>
            </div>
          </GraphicTop>

          <div className="text-card text-center max-w-md mx-auto px-2 md:px-0">
            <h1 ref={headingRef} className="text-card__heading text-hero" dangerouslySetInnerHTML={{ __html: parseHeading(hero.heading, 'hero-heading-span-text text-purple') }} />
          </div>

          <GraphicBottom ref={bottomRef}>
            <div className="o-ratio o-ratio--about-hero-bottom">
              <div className="o-ratio__content">
                <Image data={hero.graphicBottom} />
              </div>
            </div>
          </GraphicBottom>
          {/* </div> */}
        </div>

        <div className="container pad-t--sm">
          <div className="relative bg-white text-black border-t-4 border-l-blue py-12 sm:pt-16 sm:pb-14 px-10 mx-auto" style={{ maxWidth: '834px' }}>
            <div className="text-card max-w-lg mx-auto">
              <h2 className="text-card__heading text-58px max-w-sm mx-auto">{aboutIntro.heading}</h2>
              <div dangerouslySetInnerHTML={{ __html: aboutIntro.content }} />
            </div>
          </div>
        </div>
      </section>

      <section className="pad-tb">
        <div className="container">
          <div className="text-card text-center max-w-2xl mx-auto">
            <h2 className="text-card__heading text-58px">{mission.textCard.heading}</h2>
            <div className="text-card__content mx-auto" dangerouslySetInnerHTML={{ __html: mission.textCard.content }} style={{ maxWidth: '705px' }} />
            <div className="container pad-b">
              <Stats stats={mission.stats} />
            </div>
          </div>

          {/* Team */}
          <div className="">
            <div className="text-card max-w-lg mx-auto mb-14 text-center">
              <h3 className="text-card__heading text-56px font-semibold">{team?.heading}</h3>
              <p className="-mt-2">{team?.content}</p>
            </div>
            <div className="grid md:grid-cols-4 sm:grid-cols-2 gap-6">
              {team.members.map((tm, i) => (
                <div key={`tm-${i}`} className="o-ratio o-ratio--team-card">
                  <div className="o-ratio__content">
                    <div className="relative h-full w-full">
                      <TeamCard>
                        <div className="absolute inset-0 rounded-sm overflow-hidden darken-image">
                          <Image data={tm.image} className="object-cover h-full w-full" />
                        </div>
                        <div className="p-5 flex items-center justify-between flex-auto relative">
                          <div className="leading-snug">
                            <div className="font-medium" dangerouslySetInnerHTML={{ __html: tm.name }} />
                            <div dangerouslySetInnerHTML={{ __html: tm.role }} />
                          </div>
                          <div className="ml-2">
                            <Block className="h-8 w-8" />
                          </div>
                        </div>
                      </TeamCard>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="logo-section bg-tertiary pad-tb text-center">
        <div className="container">
          <div className="text-card max-w-lg mx-auto mb-12">
            <h3 className="text-card__heading text-56px font-semibold">{customers.heading}</h3>
            <p className="-mt-2">{customers.content}</p>
          </div>
          <div className={`grid md:grid-cols-3 grid-cols-2 row-gap-5 text-center mx-auto max-w-4xl`}>
            {customers.logos?.map(({ logo }) => (
              <Image key={logo.id} data={logo} className="mx-auto" />
            ))}
          </div>
        </div>
      </section>

      <Testimonial data={testimonial} />

      <section id="open-roles" className="open-roles pad-tb--lg">
        <div className="container">
          <div className="lg:mr-20">
            <h2 className="text-56px md:mb-14 mb-10">{openRoles.heading}</h2>

            {groupedByDept.map((dept) => (
              <section key={dept.name} className="open-cat">
                <h4 className="text-36px font-semibold md:mt-10 md:mb-12 mt-12 mb-8">{dept.name}</h4>
                <div className="grid md:grid-cols-2 md:gap-12 gap-8">
                  {dept.items.map(({ node }, i) => (
                    <JobPreview key={node.id} className="flex justify-start md:mb-12">
                      <Link to={node.link} className="inline-flex flex-col">
                        <h5 className="md:text-28px text-22px leading-snug font-semibold flex-auto flex flex-col justify-end">
                          <div>{node.title}</div>
                        </h5>
                        <div className="line"></div>
                        {node.jobPage && node.jobPage.location && node.jobPage.location.length && <div className="location text-20px">{node.jobPage.location}</div>}
                      </Link>
                    </JobPreview>
                  ))}
                </div>
              </section>
            ))}
          </div>
        </div>
      </section>

      <ThreeCTABoxes data={ctaBoxes} />
    </Layout>
  );
};

export const query = graphql`
  query About {
    allWpCareer {
      edges {
        node {
          id
          title
          link
          termNames
          jobPage {
            location
          }
        }
      }
    }
    wpPage(slug: { eq: "about" }) {
      id
      title
      slug
      ...SEO
      aboutPage {
        hero {
          heading
          graphicBottom {
            ...Image
          }
          graphicTop {
            ...Image
          }
        }
        aboutIntro {
          heading
          content
        }
        team {
          heading
          content
          members {
            name
            role
            image {
              ...Image
            }
          }
        }
        mission {
          textCard {
            heading
            content
          }
          stats {
            stat
            statDescription
          }
        }
        customers {
          content
          heading
          logos {
            logo {
              ...Image
            }
          }
        }
        openRoles {
          heading
        }
        testimonial {
          backgroundColour
          cite {
            name
            role
          }
          logo {
            ...Image
          }
          quote
        }

        ctaBoxes {
          ctaBoxButton {
            target
            title
            url
          }
          content
          heading
          icon {
            ...Image
          }
        }
      }
    }
  }
`;
